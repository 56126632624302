import app from 'firebase/app';
import NDP from "NDP-lib";
import 'firebase/firebase-firestore';
import 'firebase/storage';

const ndp = new NDP();
ndp.setShare();
const ndpWaitFunc = [];
let isNdpInit = false;
let isNdpSet = true;
export const ndpWaitFunction = async () => {
  console.log(isNdpInit, isNdpSet);
  if(isNdpSet){
    if(!isNdpInit){
        await (new Promise((res, rej)=>{
          ndpWaitFunc.push(()=>{
                isNdpInit = true;
                res(true);
            });
        }));
        return true;
    }
    return true;
  }else{
    return false;
  }
}
const setNdpInit = async ()=>{
  try{
    ndp.onAuthStateChanged(()=>{
        for(let i = 0; i < ndpWaitFunc.length; i++){
            ndpWaitFunc[i]();
        }
    });
    
    await ndp.init({
      grantType : "client_credentials",
      clientCredentialId : "neolab_neostudio_web_service",
      clientCredentialSecret : "Jus088bC6fB48DByaf0XkIVN3RAhlIuq",
      applicationId : 1547,
      resourceOwnerId : "neolab",
      isDev : false
    })
  }catch(e){
    console.log("!!!!!!!!!!!!!!!!!!!!!catch")
    isNdpSet = false;
  }
}
setNdpInit();




const config = {
  apiKey: "AIzaSyAY7MrI37TvkDerHsShcvOsueDpi4TGihw",
  authDomain: "neonotes2-d0880.firebaseapp.com",
  databaseURL: "https://neonotes2-d0880.firebaseio.com",
  projectId: "neonotes2-d0880",
  storageBucket: "neonotes2-d0880.appspot.com",
  messagingSenderId: "693506452621",
  appId: "1:693506452621:web:8b6600b884b8822d"
};

let base = 'https://neonotes2-d0880.firebaseio.com/'

let firestore = null
let stroage = null

export function firebaseinit() {
  if (!firestore) {
    app.initializeApp(config);
    firestore = app.firestore();
    stroage = app.storage().ref()
  }
}


/** 
  *
  * @param {string} id - page uid
  * @return {Promise} page
  *
  */
 export function getSharePage(id) {
  return firestore
    .collection("sharestudio")
    .doc(id)
    .get();
}


export function getBackgroudImage(page) {
  let section = page.section
  let owner = page.owner
  let note = page.note
  let p = page.page
  let url = 'note/' + section + '/' + owner + '/' + note +'.json?'
  let bginfo = base + url

  return new Promise((resolve, reject) => {
    fetch(bginfo)
    .then(res => res.json())
    .then(json => {
      var storageRef = stroage;
      if (json !== null) {
        if (json.pagecount === 1) {
          p = 1
        }else if(json.pagecount === 2) {
          p = p % 2
          if (p === 0) p = 2
        }
      }
      let imageref = "background/" + note + "/" + section + "_" + owner + "_" + note + "_" + p +".jpg"
      var starsRef = storageRef.child(imageref);
      return starsRef.getDownloadURL()
    })
    .then(url => {
      resolve(url)
    })
    .catch((error) => {
      console.log("user default", error);
      resolve("../../assets/img/emptypage.png")
    })
  })
}

export async function downloadData(folderid, isNdp, pageCallback, audioCallback, emptyCallback){
  if(isNdp){
    // NDP 일경우
    await ndpWaitFunction();  
    const file = await ndp.storage.getPublicFile("public/sharestudio/" + folderid, "json");
  
    if(file.errorCode === "RequestFail"){
      console.log("data is nil")
      emptyCallback()
    }else{
      pageCallback(file);
    }

  }else{
    // firebase 일 경우
    stroage.child("sharestudio/"+ folderid).listAll()
    .then(res => {
      if (res.items.length < 1) {
        console.log("data is nil")
        emptyCallback()
      }
      let promiseArray = []
      res.items.forEach( itemRes => {
        console.log(itemRes)
        if (itemRes.name.includes(".neoink")) {
          promiseArray.push()
          itemRes.getDownloadURL()
          .then(url=>fetch(url))
          .then(res => res.json())
          .then(page => pageCallback(page))
          .catch(error => console.log(error))
        }else if (itemRes.name.includes(".m4a")) {
          let audioStarted = parseInt(itemRes.name.split(".")[0])
          itemRes.getDownloadURL()
          .then(url => {
            audioCallback(url, audioStarted)
          })
          // .then(res => res.blob())
          // .then(blob => console.log("file blob", blob))
          .catch(error => console.log(error))
        }
      })
    }).catch(error => console.log(error))
  }
}

export function updateData(files) {
  let docRef = firestore.collection("sharestudio").doc();
  let folderid = docRef.id
  let promiseArray = []
  Array.from(files).forEach((file) => {
    promiseArray.push(uploadStorage(folderid, file))
  })
  return new Promise((resolve, reject) => {
    Promise.all(promiseArray)
    .then(res => {
      console.log("update data success", res)
      resolve(folderid)
      docRef.set({uid: "default"})
    })
    .catch(err => console.log(err))
  })
}

function uploadStorage(id, file) {
  let storageRef = stroage.child("sharestudio/"+ id + "/" + file.name)
  let meta = {contentType: file.type}
  if (file.name.includes(".neoink")){
    meta.contentType = "application/json"
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (res) => {
      let pageData = res.target.result
      storageRef.put(pageData, meta)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL()
      }).then( url => resolve(url))
      .catch(error => {
        console.log("error", error)
        reject()
      });
    }
    reader.readAsArrayBuffer(file)
  })
}
