import React from "react";
import IconButton from "@material-ui/core/IconButton";
import * as Icon from "@material-ui/icons";
import { Typography, ButtonBase } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import * as Tag from "../Utils/Analytics/Tag"

const styles = theme => ({
  bottom: {
    // marginTop: "auto",

    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 140,
    backgroundColor: theme.palette.background.paper, //default or paper
    zIndex: 1100
  },
  nonKrBottom : {
    height : "60px !important",
  },
  itembar: {
    display: "flex",
    justifyContent: "center",
    height: 60,

  },
  banner: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "#e3f8f8"
  },
  icon: {
    margin: "auto 0",
    height: 48
  }
});

class PageBottomView extends React.Component {
  zoomIn = () => {
    Tag.controller("zoom", "in")
    this.props.zoom(true);
  };

  zoomOut = () => {
    Tag.controller("zoom", "out")
    this.props.zoom(false);
  };

  zoomAtWidth = () => {
    Tag.controller("zoom", "width")
    this.props.zoomAtWidth(true);
  };

  zoomAtHeight = () => {
    Tag.controller("zoom", "height")
    this.props.zoomAtWidth(false);
  };

  openBannerEvent = () => {
    Tag.goShop("neosmartpen", "dimo")
    window.open("https://www.neosmartpen.co.kr/app-neostudio/")
  }

  replay = () => {
    Tag.controller("replay", "replay")
    this.props.playHandler()
  }

  linkshare = () => {
    this.props.linkshare()
  }

  replaySpeedHandler = () => {
    this.props.replaySpeedHandler()
  }

  render() {
    const { classes, scale, replaySpeed } = this.props;
    
    let lan = navigator.language || navigator.userLanguage;
    lan = lan.split('-')[0];
    const isKo = lan === "ko";
    console.log(lan);
    
    let withdown = isWidthDown("sm", this.props.width);
    return (
      <div className={`${classes.bottom} ${isKo ? "":classes.nonKrBottom}`}>
        <div className={classes.itembar}>
          <IconButton className={classes.icon} aria-label="replaySpeed" onClick={this.replaySpeedHandler}>
            <Typography> { "x " + replaySpeed}</Typography>
          </IconButton>

          <IconButton className={classes.icon} aria-label="ZoomOut" onClick={this.zoomOut}>
            <Icon.Remove />
          </IconButton>

          <Typography variant="button" align="center" style={{ width: 50, margin: "auto 0" }}>
            {scale + "%"}
          </Typography>

          <IconButton className={classes.icon} aria-label="ZoomIn" onClick={this.zoomIn}>
            <Icon.Add />
          </IconButton>

          <IconButton className={classes.icon} onClick={this.zoomAtHeight}>
            <Icon.Fullscreen />
          </IconButton>

          {!withdown && (
            <IconButton className={classes.icon} onClick={this.zoomAtWidth}>
              <Icon.ZoomOutMapRounded />
            </IconButton>
          )}

          <IconButton className={classes.icon} aria-label="Play" onClick={this.replay}>
            <Icon.PlayArrow />
          </IconButton>

          <IconButton className={classes.icon} aria-label="fileshare" onClick={this.linkshare}>
            <Icon.Link />
          </IconButton>

        </div>
        
        {isKo ? 
        (<React.Fragment >
          <div style={{height: 5, backgroundColor: "#e9e9e9"}}></div>
          <ButtonBase className={classes.banner} focusRipple onClick={this.openBannerEvent}>
              <img src="../../assets/img/banner.png" alt="Neosmart pen" width="320" height="75" style={{}} />
          </ButtonBase>
        </React.Fragment>)
        : "" }
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(PageBottomView));
