const KeyEvent = "event"
const KeyCategory = "event_category"
const KeyLabel = "event_label"
const KeyValue = "value"


export function send(event, category, label, value){
  const tag = {
    [KeyCategory]: category,
    [KeyLabel]: label,
    [KeyValue]: value
  }
  window.gtag(KeyEvent, event, tag)
}

// export function openpage(notetype , id) {
//   send("share", notetype, id, 1)
// }

export function goShop(shopName, label) {
  send(shopName, "Shop", label, 1)
}

export function controller(name, label) {
  send(name, "controller", label, 1)
}