export default class StrokeStudio {
  constructor(st, scale) {
    this.penTipType = st.penTipType;
    this.penTipColor = st.color;
    this.penThickness = st.thickness;
    this.strokeType = st.strokeType
    this.dotCount = st.dotCount;
    this.time = st.startTime
    if (typeof(st.data) === "string"){
      var binary_string = window.atob(st.data);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
      }
      this.dotData = bytes
    } else {
      this.dotData = st.data.toUint8Array()
    }

    if (st.transform) {
      if (typeof(st.transform) === "string"){
        let binary_string = window.atob(st.transform);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        this.transform  = bytes
      } else {
        this.transform  = st.transform.toUint8Array()
      }
    }

    this.scale = scale;
  }

  /**
   * Returns 'rgba(255,255,255,1)'
   * @returns {string}
   */
  getColor() {
    let color = intToByteArray(this.penTipColor);
    let r = color[2];
    color[2] = color[0];
    color[0] = r;
    let rgb = color.slice(0, 3);
    let a = color[3] / 255; 
    let hexColor = "rgba(" + rgb.toString() + "," + a + ")";
    return hexColor
    
    // let hexColor = this.penTipColor.toString(16)
    // return "#" + hexColor.slice(2,8);
  }

  getDots() {
    var dots = [];
    var time = this.time;
    let pencolor = this.penTipColor;

    let dotBlob = this.dotData;

    let dotSize = dotBlob.length / this.dotCount // 16 or 17
    let shiftDot = 0
    if (!(dotSize === 16 || dotSize === 17)) {
      console.log("invalid dot", dotBlob.length, dotSize)
      return
    }
    if (dotSize === 17) {
      shiftDot = 1
    }
    
    for (let i = 0; i < this.dotCount; i++) {

      let st = i * dotSize;
      let end = st + dotSize;
      let d = dotBlob.slice(st, end);

      let deltaTime = d[0];
      let force = toFloat(d, 1 + shiftDot);
      let x = toFloat(d, 5 + shiftDot) * this.scale;
      let y = toFloat(d, 9 + shiftDot) * this.scale;
      
      var dot = {};
      if (i === 0) {
        dot.DotType = 1
      }else if (i === this.dotCount - 1){
        dot.DotType = 3
      }else{
        dot.DotType = 2
      }
      time += deltaTime;
      dot.deltaTime = deltaTime
      dot.time = time;
      dot.penTipType = this.pentype;
      dot.penTipColor = pencolor;
      dot.f = force;
      dot.x = x;
      dot.y = y;
      dots.push(dot);
    }
    // this.invalidDotsCheck(dots)
    return dots;
  }
  // size.w, size.h, rect.x, rect.y, rect.width, rect.height
  getTransform(size, rect) {
    let transform = [1, 0, 0, 1, -rect.x / rect.width * size.w, -rect.y / rect.height * size.h]
    // let transform = [1, 0, 0, 1, 0, 0]
    if (!this.transform) {
      return transform
    } else if(this.transform.length !== 24) {
      console.log("transform size error", this.transform.length)
      return transform
    }
    transform = []
    for (let i = 0; i < 6; i ++){
      let m = toFloat(this.transform, i*4)
      transform.push(m)
    }
    if (transform[0] === 1 && transform[1] === 0 && transform[2] === 0) {
      console.log("no matrix", this)
      console.log("no matrix", this.getDots())
    }
    
    let m1 = transform[0]
    let m2 = transform[1]
    let m3 = transform[2] / rect.width * size.w
    let m4 = transform[3]
    let m5 = transform[4]
    let m6 = transform[5] / rect.height * size.h

    m3 -= rect.x / rect.width * size.w
    m6 -= rect.y / rect.height * size.h
    transform = [m1, m4, m2, m5, m3, m6]
    // console.log("transform info", transform)
    return transform
  }

  invalidDotsCheck(dots) {
    console.log("Check Dot", dots)
    dots.forEach(d => {
      if (d.f < 0 || d.f > 1) {
        console.log("Invalid Dot f", dots, this)
        return
      }
      if (d.x < 0 || d.x > 200) {
        console.log("Invalid Dot x", dots, this)
        return
      }
      if (d.y < 0 || d.y > 200) {
        console.log("Invalid Dot y", dots, this)
        return
      }
    })
  }

}




function toFloat(d, index) {
  var byte = d.slice(index, index+4)
  var view = new DataView(byte.buffer);
  return view.getFloat32(0, true)
}

// function floatToByteArray(input) {
//   var farr = new Float32Array(1)
//   farr[0] = input
//   var f = new Uint8Array(farr.buffer)
//   return f
// }

export function intToByteArray(input) {
  let arr = new Uint8Array(4)
  let dv = new DataView(arr.buffer)
  dv.setUint32(0, input, true)
  return Array.from(arr)
}
