import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Avatar
} from "@material-ui/core";

const styles = theme => ({
  top: {
    // flexGrow: 1,
    width: "100%",
    height: 60,
    backgroundColor: theme.palette.background.paper, //default or paper
    zIndex: 1100
  },
  left: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper //default or paper
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper //default or paper
  },
  arvatar: {
    color: '#fff',
    backgroundColor: "#3d87ff",
    marginRight: 20
  },
});

class PageTopView extends React.Component {
  state = {
    avatarCount: 1
  }

  avatarAction = () => {
    let devCount = this.state.avatarCount
    console.log("avatarAction", devCount)
    // this.state.avatarCount += 1
    if (devCount > 4){
      this.setState({avatarCount: 1})
      this.props.converterHandler()
    }else{
      this.setState({avatarCount: devCount+1})
    }
  }

  render() {
    const { classes, page } = this.props;
    let name = page.userName
    let url = page.photoURL
    return (
      <div className={classes.top}>
        <Grid container spacing={0} style={{ height: 60 }}>
          <Grid item xs={9} className={classes.left}>
            <img
              src="../../assets/img/logo-neostudio.png"
              alt=""
              width="130"
              height="25"
              style={{ marginLeft: 20 }}
            />
          </Grid>
          <Grid item xs={3} className={classes.right}>
            <Avatar className={classes.arvatar} alt={name} src={url} onClick={this.avatarAction}></Avatar>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(PageTopView);
