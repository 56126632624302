import React from "react";
import { CircularProgress, Divider} from "@material-ui/core";
import { MetaData, Database } from "../Utils";
import withWidth from "@material-ui/core/withWidth";
import queryString from "query-string";

// View
import PageTopView from "./PageTopView";
import PageBottomView from "./PageBottomView";
import RenderingView from "./RenderingView";
import NoDataView from "./NoDataView";
import AudioView from "./AudioView"
import ConverterView from "./ConverterView"

// Page Data
import defultPage from "../Data/default"
import errorPage from "../Data/errorPage"

const propTypes = {};

const defaultProps = {};

class PageDetailModel extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(window.location.search);
    let id =  query.id;
    let ndp = query.ndp;

    console.log("!!!!!!!!!!!!", ndp);
    console.log("id", id);
    // https://share.neostudio.io/?id=3_1012_3065_17_mrlove1@neolab.net_1672903863816.neoink & ndp=true
    let page = null
    console.log(window.location)
    if (!id) {
      id = 'default'
      page = defultPage
    } else if (id==="converter") {
      var isConverter = true
    }
    Database.firebaseinit();

    this.state = {
      id: id,
      isNdp : ndp,
      stroke: null,
      rect: null,
      bgurl: null,
      scale: 100,
      ratioWH: 1.414,
      page: page,
      scaleType: 1, // 1: ScaleTo: Height, 2 ScaleTo: Width
      isPlay: false,
      replaySpeed: 1,
      isSaveTrigger: false,
      url: null,
      playTime: 0,
      seek: 0,
      files: null,
      isConverter: isConverter
    };
  }

  loadPage = () => {
    const id = this.state.id;
    const isNdp = this.state.isNdp;
    if (!id) { return }
    if (id === "default" ) {
      this.loadingData(defultPage)
      return
    }
    console.log("loading page id", id);
    this.setState({ stroke: null, rect: null, bgurl: null });
    // for Audio
    Database.downloadData(
      id, isNdp,
      page => {
        console.log("page exist")
        this.setState({page: page})
        this.loadingData(page)
      },
      (audiourl, audioStarted)  => {
        console.log("audio infomation", audiourl, new Date(audioStarted))
        this.setState({url: audiourl, audioStarted});
      },
      () => {
        console.log("data is empty")
        this.loadPageError()
      }
    );

  };

  loadPageError = () => {
    this.setState({page: errorPage})
    this.loadingData(errorPage);
  }

  loadingData = (page) => {
    // let id = this.state.id
    console.log("Page Data", page);

    

    let pageinfo = {
      section: page.section,
      owner: page.owner,
      note: page.bookCode,
      page: page.pageNumber
    };

    MetaData.getNoteSize(pageinfo)
      .then(rect => {
        let ratio = rect.height / rect.width;
        // console.log(rect, "ratio H / W:", ratio);
        this.setState({ rect: rect, ratioWH: ratio });
      })
      .catch(e => console.log(e));

    Database.getBackgroudImage(pageinfo)
      .then(bgurl => {
        this.setState({ bgurl: bgurl });
      })
      .catch(e => {
        console.log(e);
      });
  };

  // Render
  render() {
    let isLoading = true;
    if (this.state.rect && this.state.bgurl) {
      isLoading = false;
    }
    const h = window.innerHeight;
    if (this.state.isConverter){
      return <ConverterView fileHandler={this.fileHandler} neoinkHandler={this.neoinkHandler} audioHandler={this.audioHandler} />
    }
    if (!this.state.id) {
      return <NoDataView/>
    }
    if (!this.state.page) {
        return <div>Loading...</div>
    }
    console.log("audio url", this.state.url)
    return (
      <div
        className="PageDetailView"
        style={{
          display: "flex",
          flexDirection: "column",
          height: h
        }}
      >
        <PageTopView page={this.state.page} converterHandler={this.converterHandler}/>
        {this.state.url === null?? (<AudioView url={this.state.url} playedSecondsHendler={this.playedSecondsHendler} seek={this.state.seek}/>)}
        
        <Divider />
        {isLoading ? (
          <CircularProgress style={{ margin: "auto" }} />
        ) : (
          <RenderingView
            scaleType={this.state.scaleType}
            scale={this.state.scale}
            // stroke={this.state.stroke}
            rect={this.state.rect}
            bgurl={this.state.bgurl}
            replayBine={this.replayBine}
            page={this.state.page}
            isLoading={isLoading}
            isPlay={this.state.isPlay}
            replaySpeed = {this.state.replaySpeed}
            isSaveTrigger={this.state.isSaveTrigger}
            playTime={this.state.playTime}
            seekHandler={this.seekHandler}
          />
        )}
        <Divider />

        <PageBottomView
          zoom={this.zoom}
          scale={this.state.scale}
          zoomAtWidth={this.zoomAtWidth}
          playHandler={this.playHandler}
          linkshare={this.linkshare}
          replaySpeed = {this.state.replaySpeed}
          replaySpeedHandler = { this.replaySpeedHandler}
        />
      </div>
    );
  }
  converterHandler = () => {
    this.setState({isConverter: true})
  }

  // File Open Call back
  fileHandler = (files) => {
    this.setState({id: null, files})
  }

  neoinkHandler = (page) => {
    this.setState({id:"default", page: page, isConverter: false})
    this.loadingData(page);  
  }

  audioHandler = (url, audioStarted) => {
    this.setState({url, audioStarted})
  }

  // Share Event
  linkshare = () => {
    console.log("link share")
    if (this.state.id) {
      if (this.state.id !== "default") {
        console.log("share with id", this.state.id)
        this.shareurl(this.state.id)
        return
      }
    }
    console.log("share with files")
    let files = this.state.files
    if (files) {
      Database.updateData(files).then(id=> {
        this.shareurl(id)
        this.setState({id:id})
      })
    }else{
      console.log("file is null")
    }
  }

  shareurl = (id) => {
    console.log("Share with id", id)
    let url = window.location.origin + "/?id="+id
    if (navigator.share) {
      // Web Share API is supported
      console.log("share start", url)
      navigator.share({title: "Web Share Video", url: url})
      .then( () => console.log("share complete"))
      .catch(err => console.log(err))
    } else {
      console.log("not support share")
      var textField = document.createElement('textarea')
      textField.innerText = url
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      alert("Copied this page Data.")
    }
  }

  // From AudioView To Canvas
  playedSecondsHendler =(playedSeconds) => {
    let playTime = this.state.audioStarted + (playedSeconds * 1000)
    console.log("playsecond", playedSeconds, new Date(playTime))
    this.setState({playTime})
  }
  // From Canvas To Audio
  seekHandler = (strokTime) => {
    let seek = (strokTime - this.state.audioStarted) * 0.001
    this.setState({seek})
  }

  zoom = zoomin => {
    const scaleParam = this.state.scaleType === 1 ? [50, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400] :
    [25, 50, 75, 80, 90, 100, 110, 125, 150, 175, 200]
    let scale = null;
    if (zoomin) {
      scale = scaleParam.filter(v => v > this.state.scale).shift();
    } else {
      scale = scaleParam.filter(v => v < this.state.scale).pop();
    }
    if (scale) {
      this.setState({ scale: scale});
    }
  };

  // Zoom to With or height
  zoomAtWidth = width => {
    if (width) {
      this.setState({ scale: 100, scaleType: 2 });
    } else {
      this.setState({ scale: 100, scaleType: 1 });
    }
  };


  playHandler = () => {
    // console.log("Play status", this.state.isPlay)
    if (this.state.isPlay) {
      this.setState({ isPlay: false });
    }else{
      this.setState({ isPlay: true });
    }
  }

  replaySpeedHandler = () => {
    if (this.state.replaySpeed > 31) {
      this.setState({ replaySpeed: 1 });
    }else{
      this.setState({ replaySpeed:  this.state.replaySpeed * 2});
    }
  }

  componentDidMount() {
    this.loadPage();
  }
}

PageDetailModel.propTypes = propTypes;
PageDetailModel.defaultProps = defaultProps;

const withWidthView = withWidth()(PageDetailModel);

export default withWidthView;
