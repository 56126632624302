import React from "react";
import ReactPlayer from "react-player";

const propTypes = {};

const defaultProps = {};

export default class AudioView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: null,
      pip: false,
      playing: false,
      controls: true,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      seeking: false
    }
  }

  // Controller
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = e => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    console.log("onPlay");
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    console.log("onPause");
    this.setState({ playing: false });
  };

  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      // console.log("onProgress", state);
      this.setState(state);
      this.props.playedSecondsHendler(state.playedSeconds)
    }
  };

  handleEnded = () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  handleDuration = duration => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = player => {
    this.player = player;
  };

  playStart = () => {
    this.setState({playing: !this.state.playing})
  }

  render() {
    const {url} = this.props
    const { playing, controls, light, volume, muted, loop, playbackRate, pip } = this.state
    return (
      <div>
        <ReactPlayer
          ref={this.ref}
          url={url}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={() => console.log("onReady")}
          onStart={() => console.log("onStart")}
          onPlay={this.handlePlay}
          onEnablePIP={this.handleEnablePIP}
          onDisablePIP={this.handleDisablePIP}
          onPause={this.handlePause}
          onBuffer={() => console.log("onBuffer")}
          onSeek={e => console.log("onSeek", e)}
          onEnded={this.handleEnded}
          onError={e => console.log("onError", e)}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          height={56}
          width={'100%'}
        ></ReactPlayer>
      </div>
    );
  }
  shouldComponentUpdate(nextProps, nextState){
    if (nextProps.seek !== this.props.seek) {
      console.log("Seek change", nextProps.seek)
      this.player.seekTo(nextProps.seek)
      // this.player.played = nextProps.seek
      // this.setState({played:nextProps.seek})
      return false
    }
    return true
  }
}

AudioView.propTypes = propTypes;
AudioView.defaultProps = defaultProps;
