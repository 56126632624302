import React from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';
import {theme} from './Theme'
import { CssBaseline } from '@material-ui/core';
import PageDetailModel from './PageDetail/PageDetailModel';

class App extends React.Component {
  constructor(prop){
    super(prop)
    
    var agent = navigator.userAgent.toLowerCase();
    if ( (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || (agent.indexOf("msie") !== -1)) {
      window.location.href = './assets/notsupport';
    }
  }
  render() {
    return (
      <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <PageDetailModel />
      </div>
    </MuiThemeProvider>
    )
  }
};

export default App;
